<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ valid }">
      <show-model :text="model"></show-model>
      <master-render :rows="rows" v-model="model"></master-render>
      <div class="text-right">
        <v-btn text class="primary" @click="submit()" :disabled="!valid">Confirm</v-btn>
        <v-btn text class="ml-3" @click="reset()">Reset</v-btn>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { InputType } from "./../../js/constants";

export default {
  data() {
    return {
      model: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      }
    };
  },
  methods: {
    submit() {
      let url = "/account/changePassword";

      this.$http.post(url, this.model).then(res => {
        this.showAlertSuccess("เปลี่ยนรหัสผ่านสำเร็จ");
        setTimeout(() => {
          this.$router.push({
            name: "login"
          });
        }, 1000);
      });
    },
    reset() {
      this.model = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      };
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    }
  },
  computed: {
    rows() {
      return [
        [
          {
            name: "currentPassword",
            text: "Current Password",
            type: InputType.password,
            rule: {
              required: true,
              min: 7,
              max: 12
            }
          },
          {
            type: InputType.offset
          },
          {
            type: InputType.offset
          }
        ],
        [
          {
            name: "newPassword",
            text: "New Password",
            type: InputType.password,
            rule: {
              required: true,
              min: 7,
              max: 12,
              is_not: "currentPassword"
            }
          },
          {
            name: "confirmPassword",
            text: "Confirm Password",
            type: InputType.password,
            rule: {
              confirmed: "newPassword",
              required: true
            }
          },
          {
            type: InputType.offset
          }
        ]
      ];
    }
  }
};
</script>

